import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import * as Action from "../appRedux/actions/";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import { userSignIn } from "../appRedux/actions/Auth";
import { FETCH_ERROR } from "../constants/ActionTypes";

const FormItem = Form.Item;

const SignIn = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => auth.token);
  const { requirAccessMessage } = useSelector(({ commonData }) => commonData);
  const [messagetoshow, setmessage] = useState([]);

  useEffect(() => {
    if (requirAccessMessage) {
      var a = requirAccessMessage.split("|");
      setmessage(a);
    }
  }, [requirAccessMessage]);
  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        dispatch(userSignIn(values));
      }
    });
  };

  useEffect(() => {
    if (token !== null) {
      props.history.push("/");
    }
  }, [token, props.history]);

  const { getFieldDecorator } = props.form;

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            {/* <div className="gx-app-logo-content-bg"></div> */}
            <div className="gx-app-logo-wid">
              <h1>
                <img
                  alt=""
                  src={require("assets/images/FocusGP_logo.svg")}
                  style={{ width: 200 }}
                />
                {/* <IntlMessages id="app.userAuth.signIn" /> */}
              </h1>
              <p
                style={{
                  fontWeight: 600,
                  margin: "21px 0",
                  fontSize: "30px",
                  color: " #fa4616",
                  marginTop: "20px",
                }}
              >
                Se connecter
              </p>
              <p style={{ fontSize: "16px", color: " #fa4616" }}>
                Veuillez utiliser votre courriel pour accéder à l'application
              </p>
            </div>
            <div className="gx-app-logo"></div>
          </div>
          <div className="gx-app-login-content">
            <Form
              onSubmit={handleSubmit}
              className="gx-signin-form gx-form-row0"
            >
              <FormItem>
                {getFieldDecorator("email", {
                  rules: [
                    {
                      required: true,
                    },
                  ],
                })(<Input type="email" placeholder="Email" />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator("password", {
                  rules: [
                    { required: true, message: "Please input your Password!" },
                  ],
                })(<Input type="password" placeholder="Password" />)}
              </FormItem>

              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
              </FormItem>
            </Form>
          </div>
          <InfoView />
        </div>
        <div
          style={{
            fontSize: "16px",
            color: " #fa4616",
            paddingLeft: "18px",
            paddingTop: "7px",
          }}
        >
          <p>{messagetoshow[0]}</p>
          <p>{messagetoshow[1]}</p>
        </div>
      </div>
    </div>
  );
};

const WrappedNormalLoginForm = Form.create()(SignIn);

export default WrappedNormalLoginForm;
