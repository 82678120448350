//LocalHost Constant
export const LOCALHOST = "https://fnx-api.coolkaru.com/";

//TimeOut
export const TimeOutMinutes = "60";

//Toggles
export const NewViewInterfaceToggle = "true";
export const OpportunityToggle = "false";
export const GanttChartToggle = "true";
export const googleMapsToggle = "true";

//google api
export const GOOGLE_API_KEY = "AIzaSyDPRSh0g4DfYTF9Zzhzwq-_oz1E_ZD_Jcs";

// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";
export const TOGGLE_PROGRESS_BAR_LOADER = "TOGGLE PROGRESS BAR LOADER";
//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_NO_ACCESS_MESSAGE = "SHOW_NO_ACCESS_MESSAGE";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";
export const TOGGLE_LOADER = "TOGGLE LOADER";
export const SET_LOADER = "SET_LOADER";
export const TOGGLE_EMPLOYEE_LOCKUP_LOADER = "TOGGLE_EMPLOYEE_LOCKUP_LOADER";
export const TOGGLE_COMPANY_LOCKUP_LOADER = "TOGGLE_COMPANY_LOCKUP_LOADER";
export const SET_PROJECT_ID = "SET PROJECT ID";
//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const USER_DATA = "user_data";
export const USER_TOKEN_SET = "user_token_set";
export const UPDATE_TOAST = "UPDATE TOAST";
//Create Project
export const SET_EMPLOYEE_DETAILS = "set_employee_details";
export const SET_COMPANY_DETAILS = "set_company_details";
export const SET_PROJECT_LOCATIONS = "SET_PROJECT_LOCATIONS";
export const GET_PROJECT_LOCATIONS_BY_ID = "GET_PROJECT_LOCATIONS_BY_ID";
export const ADD_PROJECT_LOCATIONS = "ADD_PROJECT_LOCATIONS";
export const UPDATE_PROJECT_LOCATIONS = "UPDATE_PROJECT_LOCATIONS";
export const SET_ALL_LOCATIONS = "SET_ALL_LOCATIONS";
export const SET_COMPANY_REPRESENTATIVE_DETAILS =
  "set_company_representative_details";
export const SET_COMPANY_REPRESENTATIVE_ADDRESS =
  "set_company_representative_address";
export const SET_COMPANY_CLIENT_ADDRESS = "SET COMPANY CLIENT ADDRESS";
export const UPLOAD_PUSH_IDENTIIFIER = "PUSH IDENTIFIER ARRAYS";
export const SET_FORM_STATE = "SET_FORM_STATE";
export const GENERIC_APP_FILE_UPLOAD_SET = "GENERIC APP FILE UPLOAD SET";
export const GET_CLIENT_TYPES = "GET_CLIENT_TYPES";
export const GET_CURRENCY_DETAILS = "GET_CURRENCY_DETAILS";
export const GET_FORMTYPES_DETAILS = "GET_FORMTYPES_DETAILS";
export const GET_EMPLOYEE_RATE = "Get_employee_rate";
export const GET_MANDATEPROJECTS_LIST = "Get_mandateprojects_list";
//Planification
export const SET_PLANIFICATION_FORM_DATA = "SET PLANIFICATION FORM DATA";
export const UPDATE_PLANIFICATION_FORM_DATA = "UPDATE PLANIFICATION FORM DATA";
export const UPDATE_PLANIFICATION_DISCIPLINE = "UPDATE PLANIFICATION DISIPLINE";
export const SET_PLANIFICATION_TABLE_DATA = "SET PLANIFICATIOM TABLE DATA";
export const SET_PLANIFICATION_DISCIPLINE = "SET PLANIFICATION DISCIPLINE";
export const GET_PLANIFICATION_BY_ID = "GET PLANIFIATION BY ID";
export const ADD_EDIT_VALUE_PLANIFICATION = "ADD_EDIT_VALUE_PLANIFICATION";
export const ADD_EDIT_VALUE_PLANIFICATION_DISCIPLINE =
  "ADD_EDIT_VALUE_PLANIFICATION_DISCIPLINE";
export const RESET_PLANIFICATION_FORM = "RESET_PLANIFICATION_FORM";
//Risk Analysis
export const DELETE_RISK_ANALYSIS = "DELETE RISK ANALYSIS";
export const GET_RISK_ANALYSIS_BY_ID = "GET RISK ANALYSIS BY ID hellooooooooo";
export const ADD_RISK_TABLE_DATA = "ADD RISK ANALYSIS SECOND TABLE DATA";
export const RISK_ANALYSIS_TABLE_EDIT_DATA = "RISK ANALYSIS TABLE EDIT DATA";
export const RISK_ANALYSIS_RESET_FORM_DATA = "RISK ANALYSIS RESET FORM DATA";
export const UPDATE_RISK_ANALYSIS_INNER_TABLE = "RISK ANALYSIS INNER TABLE";
export const RISK_ANALYSIS_EDIT_INNER_TABLE = "RISK ANALYSIS EDIT INNER TABLE";
//FULL PROJECT LIST
export const SET_PROJECT_LIST = "set_project_list";
export const SET_PROJECT_BY_ID = "set_project_by_id";
export const SET_AVAILABLE_SUFF_FOR_PROJECT = "SET_AVAILABLE_SUFF_FOR_PROJECT";
//GET LIST BY CHARGE
export const GET_LIST_BY_CHARGE = "GET LAST BY CHARGE";
export const GET_LIST_BY_STATE = "GET LIST BY STATE";
export const RUN_TIME_KEY_LIST = "RUN TIME KEY IN LIST BY CHARGE";
export const RUN_TIME_STATE_KEY_LIST = "RUN TIME KEY IN LIST BY STATE";
//GET LIST BY DEPARTMENT
export const GET_LIST_BY_DEPARTMENT = "GET LAST BY DEPARTMENT";

//GET PROJECT HEADER DETAILS
export const GET_HEADER_BY_PROJECT_ID = "get_header_by_project_id";
//LIST BY CUSTOMER
export const GET_LIST_BY_CUSTOMER = "GET LIST BY CUSTOMERS";
export const RUN_TIME_KEY_LIST_BY_CUSTOMER =
  "MAKE RUNTIME KEY FOR LIST BY CUSTOMERS";
//FULL LIST
export const GET_FULL_LIST = "GET FULL LIST";
//Approvals
export const GET_APPROVAL_LIST = "GET APPROVAL LIST";
//Project Short Details
export const GET_PROJECT_SHORT = "GET PROJECT SHORT";
export const GET_ALL_PROJECT_NUMBERS = "GET_ALL_PROJECT_NUMBERS";
//MyApprovals
export const GET_MYAPPROVAL_LIST = "GET MYAPPROVAL LIST";
export const GET_MYAPPROVAL_CORPORATERISK_LIST =
  "GET_MYAPPROVAL_CORPORATERISK_LIST";
//Accounts
export const GET_ACCOUNTS_LIST = "GET ACCOUNTS LIST";
//Accounting
export const GET_INVOICE_LIST = "GET INVOICE LIST";
export const GET_PROJETACCOUNTING_BY_PROJETID =
  "GET_PROJETACCOUNTING_BY_PROJETID";
//Project Phase
export const GET_PROJECT_PHASE = "GET PROJECT PHASE";
export const GET_SUB_PROJECT_PHASE_BY_ID = "GET SUB PROJECT PHASE BY ID";
export const GET_SUB_PROJECT_PHASES = "GET SUB PROJECT PHASES";
export const SET_CREATE_PHASE_WAIT = "SET_CREATE_PHASE_WAIT";
export const SET_CREATE_PHASE_SUCCESS = "SET_CREATE_PHASE_SUCCESS";

export const SET_PROJECT_PHASE_BY_ID = "SET PROJECT PHASE BY ID";
export const SET_PHASE_MODE = "SET PHASE MODE";
export const DYNAMIC_PHASE_KEY = "DYNAMIC PHASE KEY";
export const REPLACE_PHASE_ATTACHEMENT = "REPLACE PHASE ATTACHEMENT";
export const RESET_FORM_PHASE = "RESET FORM PHASE";
export const ADD_PHASE_OFFER_AVENANT = "ADD PHASE OFFER AVINANT";
export const ADD_EMPLOYEE_RATES_PHASE_OFFER = "ADD_EMPLOYEE_RATES_PHASE_OFFER";
export const ADD_RATE_BY_EMPLOYEE_CLASS_PHASE_OFFER =
  "ADD_RATE_BY_EMPLOYEE_CLASS_PHASE_OFFER";
export const ADD_EMPLOYEE_RATES_PROJECT_ACCOUNTING =
  "ADD_EMPLOYEE_RATES_PHASE_OFFER_PROJECT_ACCOUNTING";
export const ADD_RATE_BY_EMPLOYEE_CLASS_PROJECT_ACCOUNTING =
  "ADD_RATE_BY_EMPLOYEE_CLASS_PROJECT_ACCOUNTING";
export const SET_AVENANT_TABLE_PHASE_OFFER = "SET AVENANT TABLE PHASE OFFER";
export const ADD_AVENANT_TABLE_PHASE_OFFER = "ADD AVENANT TABLE PHASE OFFER";
export const DELETE_AVENANT_PHASE_OFFER = "DELETE AVENANT PHASE OFFER";
export const ADD_EQUIPMENTS_PHASE = "ADD_EQUIPMENTS_PHASE";
export const ADD_LAB_TEST_PHASE = "ADD_LAB_TEST_PHASE";
export const NEXT_PHASE_NUMBER = "NEXT_PHASE_NUMBER";
export const NEXT_SUBPHASE_NUMBER = "NEXT_SUBPHASE_NUMBER";
//Phase Offer
export const GET_PROJECT_PHASE_OFFER = "GET_PROJECT_PHASE_OFFER";
export const ADD_IDENTIFIER_PHASE_OFFER = "ADD IDENTIFIER IN PHASE OFFERs";
export const GET_SPECIFIC_PHASE_PROJECT = "GET SPECIFIC PHASE PROJECT";
export const DYNAMIC_PHASE_KEY_IDENTIFIER = "DYNAMIC PHASE KEY IDENTIFIER";
export const RESET_FORM_PHASE_OFFRE = "RESET FORM PHASE OFFRE FORM";
//Avenants
export const GET_AVENANTS_BY_PROJECT_ID = "GET AVENANTS BY PROJECT ID";
export const SET_AVENANT_TABLE_DATA = "SET_AVENANT_TABLE_DATA";
export const GET_AVENANTS_BY_ID = "GET_AVENANTS_BY_ID";
export const DELETE_AVENANT_SUCCESS = "DELETE_AVENANT_SUCCESS";
export const UPLOAD_IDENTIFIER_BY_KEY_AVENANT =
  "UPLOAD IDENTIFIER BY KEY IN AVENANT";
export const UPDATE_IDENTIFIER_BY_KEY_AVENANT = "UPDATE IDENTIFIER IN AVENANT";
export const RESET_AVENANT_FORM = "RESET AVENANT FORM";
export const GET_HISTORY_OF_AVENANT = "GET HISTORY OF AVENANT";
export const GET_TRANSECTION_HISTORY_OF_AVENANT =
  "GET TRANSECTION HISTORY OF AVENANT";
//Planification Surveillance
export const SET_SURVEILLANCE_TABLE_DATA = "SET_SURVEILLANCE_TABLE_DATA";

//Transactions Module
//approve history
export const HISTORY_APPROVE = "history_approve";

//transaction complete history
export const TRANSACTIONS = "transactions";
export const MODIFIEDFIELDS = "MODIFIEDFIELDS";
export const RESET_MODIFIEDFIELDS = "RESET_MODIFIEDFIELDS";

export const GET_SURVEILLANCE_BY_ID = "GET_SURVEILLANCE_BY_ID";
export const PLANIFICATION_KEY_IDENTIFIER = "PLANIFICATION KEY IDENTIFIER";
export const RESET_SURVEILLANCE_FORM = "RESET SURVEILLANCE FORM";
export const UPDATE_IDENTIFIER_BY_KEY_SURVEILLANCE =
  "UPDATE IDENTIFIER IN SURVEILLANCE";
//UPLOAD ACTION CONSTANT
export const SET_IDENTIFIER_BY_FORM_NAME = "SET IDENTIFIER BY FORM NAME";
export const UPDATE_IDENTIFIER_BY_FORM_NAME = "UPDATE IDENTIFIER BY FORM NAME";
export const REMOVE_IDENTIFIER_BY_FORM_NAME = "REMOVE IDENTIFIER BY FORM NAME";
export const HANDLE_COUNTER_BY_NAME = "HANDLE COUNTER BY NAME";
export const RESET_COUNTER_BY_FORM = "RESET COUNTER IN DRAGGER";
export const RESET_WHOLE_STATE_UPLOADS = "RESET WHOLE STATE UPLOADS";

//COMMON
export const GET_ALL_LOOKUPS_BY_LANGUAGE = "GET_ALL_LOOKUPS_BY_LANGUAGE";
export const GET_OFFICE_LOCATION = "GET_OFFICE_LOCATION";
export const GET_BILLING_TECHNICIAN = "GET_BILLING_TECHNICIAN";
export const GET_ALL_ACTIVEINACTIVE_LOOKUPS_BY_LANGUAGE =
  "GET_ALL_ACTIVEINACTIVE_LOOKUPS_BY_LANGUAGE";

//REVIEWFORM
export const SET_REVIEWFORM_TABLE_DATA = "SET REVIEWFORM TABLE DATA";
export const RESET_REVIEWFORM_FORM = "RESET REVIEWFORM FORM";
export const GET_REVIEWFORM_BY_ID = "GET REVIEWFORM BY ID";

export const ResetManagers = "ResetManagers";
//POSTMORTEM
export const GET_POSTMORTEM_LIST = "GET POSTMORTEM LIST";
export const GET_POSTMORTEM_BY_PROJECTID = "GET POSTMORTEM BY PROJECTID";
export const GET_MANAGER_HISTORY_BY_PROJECTID =
  "GET MANAGER HISTORY BY PROJECTID";
//OPPORTUNITIES
export const GET_OPPORTUNITY = "GET OPPORTUNITY";
export const GET_SPECIFIC_OPPORTUNITIES = "GET_SPECIFIC_OPPORTUNITIES";
export const GET_OPPORTUNITIES_BY_DEPT = "GET_OPPORTUNITIES BY DEPARTMENT";
export const GET_ALL_OPPORTUNITIES = "GET_ALL_OPPORTUNITIES";
export const GET_ALL_OPPORTUNITIES_BY_LAST_WEEK =
  "GET ALL OPPORTUNITIES BY LAST WEEK";
export const GET_ALL_OPPORTUNITIES_BY_LAST_TWO_DAYS =
  "GET ALL OPPORTUNITIES BY LAST TWO DAYS";
export const GET_OPPORTUNITIES_ALERTS = "GET_OPPORTUNITIES_ALERTS";
export const GET_OPPORTUNITIES_ORDER_DOCUMENT =
  "GET_OPPORTUNITIES_ORDER_DOCUMENT";
export const RESET_OPPORTUNITY = "RESET OPPORTUNITY";
export const SET_OPPORTUNITY_BY_ID = "SET_OPPORTUNITY_BY_ID";
export const UPDATE_DECISION = "UPDATE_DECISION";
export const MY_OPPORTUNITIES = "MY APPORTUNITIES";

//BIDDING
export const SET_BIDDING_QUOTE_TABLE_DATA = "SET_BIDDING_QUOTE_TABLE_DATA";
export const GET_BIDDING_MASTERDATA = "GET BIDDING MASTER DATA";
export const SET_BIDDING_BY_ID = "SET BIDDING BY ID";
export const GET_DISTRIBUTION_DETAILS = "GET DISTRIBUTION DETAILS";
export const RESET_BIDDING_BY_ID = "RESET BIDDING BY ID";
export const TRANSFROM_PHASEOFFER_TO_PROJECT =
  "TRANSFROM PHASEOFFER TO PROJECT";
export const RESET_TRANSFROM_PHASEOFFER_TO_PROJECT =
  "RESET TRANSFROM PHASEOFFER TO PROJECT";
export const GET_BILLING_METHOD_LAB = "GET BILLING METHOD LAB";
export const GET_LIST_TRAILS = "GET LIST TRAILS";
export const GET_LIST_EQUIPMENT_VEHICLES = "GET LIST EQUIPMENT VEHICLES";

//NEWPOSTMORTEM
export const GET_NEWPOSTMORTEM_LIST = "GET NEW POOSTMORTEM LIST";
export const GET_NEWPOSTMORTEM_BY_PROJECTID = "GET NEWPOPSTMORTEM BY PROJECTID";
export const GET_NEWPOSTMORTEM_PERCENTAGE = "GET_NEWPOSTMORTEM_PERCENTAGE";
export const NEW_POSTMORTEM_DOCUMENT = "NEW_POSTMORTEM_DOCUMENT";
export const RESET_NEWPOSTMORTEM_FORM = "RESET NEWPOSTMORTEM FORM";
export const UPDATE_IDENTIFIER_BY_NEW_POSTMORTEM =
  "UPDATE_IDENTIFIER_BY_NEW_POSTMORTEM";
export const UPLOAD_IDENTIFIER_BY_NEW_POSTMORTEM =
  "UPLOAD_IDENTIFIER_BY_NEW_POSTMORTEM";
export const FILE_UPLOAD_SET_NEWPOSTMORTEM = "FILE_UPLOAD_SET_NEWPOSTMORTEM";
export const INIT_URLS = "INIT_URLS";

export const GET_CONTRACT_REVIEW_DETAILS = "GET CONTRACT REVIEW DETAILS";
export const ADD_CONTRACT_REVIEW_DETAILS = "ADD CONTRACT REVIEW DETAILS";
export const UPDATE_CONTRACT_REVIEW_DETAILS = "UPDATE CONTRACT REVIEW DETAILS";

//PROJECT
export const GET_PROJECT_DETAILS = "GET_PROJECT_DETAILS";
