import * as Action from "../../constants/ActionTypes";
import * as Actions from "./index";
import _ from "lodash";
import axios from "util/Api";
import React from "react";
import IntlMessages from "util/IntlMessages";
export const getApprovalList = (
  formid,
  formtype,
  department,
  title,
  resposibleNames,
  projectNumber,
  statutProjet,
  empDepartmentId
) => {
  let formidparam = formid || "";
  let formtypeparam = formtype || "";
  department = department || "";
  title = title || "";
  resposibleNames = resposibleNames || "";
  projectNumber = projectNumber || "";
  statutProjet = statutProjet || "";
  empDepartmentId = empDepartmentId || "";

  return (dispatch) => {
    dispatch(Actions.GetAllLookupsByLanguage());
    return axios
      .get(
        `${Action.LOCALHOST}api/Project/GetListByApprovals?formid=` +
          formidparam +
          "&formtype=" +
          formtypeparam +
          "&department=" +
          department +
          "&title=" +
          title +
          "&resposibleNames=" +
          resposibleNames +
          "&projectNumber=" +
          projectNumber +
          "&statutProjet=" +
          statutProjet +
          "&empDepartmentId=" +
          empDepartmentId
      )
      .then((res) => {
        if (res.data.Payload) {
          const payload = _.groupBy(res.data.Payload, function (o) {
            // Sagar - Performance change
            //return o.Department;
            return o.DepartmentId;
          });
          console.log("group by ", payload);
          return dispatch({
            type: Action.GET_APPROVAL_LIST,
            payload: payload,
          });
        } else {
          return dispatch({
            type: Action.SHOW_MESSAGE,
            payload: <IntlMessages id="Common.DataFetchError" />,
          });
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
export const changeStatus = (
  id,
  status,
  type,
  advancedFilters,
  empDeptId,
  isAutoApproved,
  expandedDepartment
) => {
  isAutoApproved = isAutoApproved || false;
  return (dispatch) => {
    return axios
      .post(
        `${Action.LOCALHOST}api/Project/ChangeStatus?id=${id}&status=${status}&Documenttype=${type}&isAutoApproved=${isAutoApproved}`
      )
      .then((res) => {
        console.log(res);
        if (res.data.Payload) {
          dispatch(
            Actions.fetchSuccess(<IntlMessages id="Common.StatusChange" />)
          );
          if (status === 1) {
            return dispatch(
              getApprovalList(
                "",
                advancedFilters?.FormType,
                advancedFilters?.Department ?? expandedDepartment,
                advancedFilters?.TitleSearch,
                advancedFilters?.ResposibleNames,
                advancedFilters?.ProjectNumber,
                advancedFilters?.StatutProjetSearch,
                empDeptId
              )
            );
          } else {
            return dispatch(
              Actions.getAccountList(
                "",
                advancedFilters?.FormType,
                advancedFilters?.Department,
                advancedFilters?.TitleSearch,
                advancedFilters?.ResposibleNames,
                advancedFilters?.ProjectNumber,
                advancedFilters?.StatutProjetSearch
              )
            );
          }
        } else {
          return dispatch(
            Actions.fetchError(
              "Failed",
              <IntlMessages id="Common.StatusNotChange" />
            )
          );
        }
      })
      .catch((err) => {
        const errors = err.StatusDesc;
        return dispatch({
          type: Action.FETCH_ERROR,
          payload: errors,
        });
      });
  };
};
